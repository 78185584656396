import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { FormikErrors, FormikTouched } from 'formik';

import InputErrorField from 'Components/utility/InputErrorField';
import InputPassword from 'Components/utility/InputPassword';
import PasswordRequirements from 'Components/utility/PasswordRequirements';

type Props = {
  placeholder: string;
  label: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  handleBlur: (e: any) => void;
  password: string;
  onValidate?: (meetsPasswordRequirements: boolean) => void;
  identities?: Identity[];
  nativeID: string;
  errors: FormikErrors<{
    [key: string]: string;
  }>;
  touched: FormikTouched<{ [key: string]: string }>;
  isDisabled?: boolean;
};

const PasswordInputValidator: React.FC<Props> = ({
  placeholder,
  label,
  setFieldValue,
  handleBlur,
  password,
  onValidate,
  errors,
  touched,
  identities = [],
  nativeID,
  isDisabled,
}) => {
  const [isBlurred, setIsBlurred] = useState(false);
  const [meetsPasswordRequirements, setMeetsPasswordRequirements] = useState(false);

  const onFocus = useCallback(() => {
    setIsBlurred(false);
  }, []);

  const filteredIdentities = useMemo(() => {
    const keysToFilter = ['min'];

    return identities.filter(({ data: { key } }) => keysToFilter.includes(key));
  }, [identities]);

  const requirements: Requirement[] = useMemo(() => {
    return filteredIdentities.map(({ data, ...rest }) => {
      const match = new RegExp(data.regex).test(password.trim());

      const isInvalid = isBlurred && !match;

      return { match, isInvalid, data, ...rest };
    });
  }, [password, filteredIdentities, isBlurred]);

  const onBlur = useCallback(
    (e: any) => {
      setIsBlurred(true);
      handleBlur(e);
    },
    [handleBlur]
  );

  useEffect(() => {
    const remainingRequirements = requirements.filter((requirement) => !requirement.match);

    const meetsPasswordRequirements = !remainingRequirements.length;

    setMeetsPasswordRequirements(meetsPasswordRequirements);

    onValidate && onValidate(meetsPasswordRequirements);
  }, [requirements, onValidate]);

  return (
    <>
      <InputPassword
        isInvalid={
          (!meetsPasswordRequirements && isBlurred) ||
          (errors?.[nativeID] && touched?.[nativeID] ? true : false)
        }
        placeholder={placeholder}
        label={label}
        nativeID={nativeID}
        setFieldValue={setFieldValue}
        onBlur={onBlur}
        value={password}
        onFocus={onFocus}
        isDisabled={isDisabled}
      />
      <PasswordRequirements requirements={requirements} />
      {errors?.[nativeID] && touched?.[nativeID] && (
        <InputErrorField errorMessage={errors?.[nativeID]} nativeID={nativeID} />
      )}
    </>
  );
};

export default memo(PasswordInputValidator);
